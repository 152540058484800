import { Script } from "gatsby";
import React from "react";

const Hubspot = () => (
  <Script
    type="text/javascript"
    id="hs-script-loader"
    async
    defer
    src="//js.hs-scripts.com/24095788.js"
  />
);

export default Hubspot;
