// extracted by mini-css-extract-plugin
export var aboutCompany = "footer-module--aboutCompany--78ffc";
export var addressInfo = "footer-module--addressInfo--cdf7e";
export var certificates = "footer-module--certificates--8743d";
export var email = "footer-module--email--0dc35";
export var footerInner = "footer-module--footerInner--a55c6";
export var footerWrapper = "footer-module--footerWrapper--692b5";
export var linksBlock = "footer-module--linksBlock--6389e";
export var linksHead = "footer-module--linksHead--0ba2c";
export var linksInfo = "footer-module--linksInfo--64ae4";
export var linksList = "footer-module--linksList--f33be";
export var secondaryFooterInner = "footer-module--secondaryFooterInner--11218";
export var secondaryFooterText = "footer-module--secondaryFooterText--8bab2";
export var secondaryFooterWrapper = "footer-module--secondaryFooterWrapper--a5124";
export var socialLinksList = "footer-module--socialLinksList--eca79";