import React from "react";
import Layout from "./src/components/layout/layout";
import { getUtmParams, setCookie, isObject } from "./src/utils";

const transitionDelay = 500;

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);

export const onRouteUpdate = ({ location }) => {
  const utmParams = getUtmParams(location.search);
  const isTestSession = location.search.includes("test_session=true");

  if (isObject(utmParams)) {
    setCookie({ name: "utm_params", value: utmParams, expiry: 7 });
  }

  if (isTestSession) {
    setCookie({ name: "test_session", value: true, expiry: 1 });
  }
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
  prevRouterProps,
}) => {
  if (prevRouterProps) {
    const prevLocation = prevRouterProps.location;
    if (prevLocation.pathname !== location.pathname && location.hash) {
      window.scrollTo([0, 0]);
      window.setTimeout(
        () =>
          document
            .getElementById(location.hash.replace("#", ""))
            .scrollIntoView({
              behavior: "smooth",
            }),
        transitionDelay * 2,
      );
    }
    if (prevLocation.pathname === location.pathname && location.hash) return;
  }

  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  } else if (location.hash) {
    window.scrollTo([0, 0]);
    window.setTimeout(
      () =>
        document.getElementById(location.hash.replace("#", "")).scrollIntoView({
          behavior: "smooth",
        }),
      transitionDelay * 2,
    );
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay,
    );
  }

  // eslint-disable-next-line consistent-return
  return false;
};
