import React, { useState, createContext, useMemo } from "react";
import { DEFAULT_USER_COUNTRY } from "../components/contactSales/constants";

export const GlobalContext = createContext();

function GlobalContextProvider({ children }) {
  const [email, setEmail] = useState("");
  const [countryCodes, setCountryCodes] = useState([]);
  const [country, setCountry] = useState(DEFAULT_USER_COUNTRY);
  const [navBG, setNavBG] = useState(false);
  const [leadFormVisible, setLeadFormVisible] = useState(false);
  const [leadFormType, setLeadFormType] = useState("");

  const contextData = useMemo(
    () => ({
      email,
      setEmail,
      navBG,
      setNavBG,
      leadFormVisible,
      setLeadFormVisible,
      leadFormType,
      setLeadFormType,
      countryCodes,
      setCountryCodes,
      country,
      setCountry,
    }),
    [email, navBG, leadFormVisible, leadFormType, countryCodes, country],
  );

  return (
    <GlobalContext.Provider value={contextData}>
      {children}
    </GlobalContext.Provider>
  );
}

export default GlobalContextProvider;
