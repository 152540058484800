// extracted by mini-css-extract-plugin
export var actionDetail = "contactSales-module--actionDetail--c430b";
export var actionItem = "contactSales-module--actionItem--148eb";
export var clientsList = "contactSales-module--clientsList--213cf";
export var contactAction = "contactSales-module--contactAction--e6a41";
export var contactSalesForm = "contactSales-module--contactSalesForm--761bf";
export var contactSalesInner = "contactSales-module--contactSalesInner--2862a";
export var contactSalesModal = "contactSales-module--contactSalesModal--7a683";
export var contactSalesModalOverlay = "contactSales-module--contactSalesModalOverlay--2a511";
export var contactSalesWrapper = "contactSales-module--contactSalesWrapper--7100a";
export var content = "contactSales-module--content--a28e4";
export var formWrapper = "contactSales-module--formWrapper--779d3";
export var hideOnMobile = "contactSales-module--hideOnMobile--a6509";
export var leadFormWrapper = "contactSales-module--leadFormWrapper--594c2";
export var leadSubmitted = "contactSales-module--leadSubmitted--1ba93";
export var logo = "contactSales-module--logo--e932b";
export var modalCloseBtn = "contactSales-module--modalCloseBtn--a38ae";
export var modalCompaniesLogos = "contactSales-module--modalCompaniesLogos--d28e4";
export var phoneContainer = "contactSales-module--phoneContainer--0c21e";
export var reqStar = "contactSales-module--reqStar--4d0ee";
export var row = "contactSales-module--row--2a8a4";
export var testimonialsData = "contactSales-module--testimonialsData--618c3";
export var testimonialsWrapper = "contactSales-module--testimonialsWrapper--4c1db";