// extracted by mini-css-extract-plugin
export var activeTag = "cookieBar-module--activeTag--1b210";
export var btnGroup = "cookieBar-module--btnGroup--2accd";
export var btnGroupWrapper = "cookieBar-module--btnGroupWrapper--189d5";
export var btnNo = "cookieBar-module--btnNo--1f27a";
export var btnYes = "cookieBar-module--btnYes--677a8";
export var closeBtn = "cookieBar-module--closeBtn--9e56b";
export var cookieBarWrapper = "cookieBar-module--cookieBarWrapper--81292";
export var cookieDetails = "cookieBar-module--cookieDetails--98e0a";
export var cookieSettingsModal = "cookieBar-module--cookieSettingsModal--6888c";
export var cookieSettingsOverlay = "cookieBar-module--cookieSettingsOverlay--dbd2b";
export var ctaArea = "cookieBar-module--ctaArea--1dc4b";
export var detailItem = "cookieBar-module--detailItem--fa620";
export var name = "cookieBar-module--name--d900f";
export var saveBtn = "cookieBar-module--saveBtn--f5355";
export var setting = "cookieBar-module--setting--e2c75";
export var settingName = "cookieBar-module--settingName--409c8";
export var settingWrapper = "cookieBar-module--settingWrapper--1b1c6";
export var settingsHeader = "cookieBar-module--settingsHeader--29fba";
export var slider = "cookieBar-module--slider--4709f";
export var switchDiv = "cookieBar-module--switchDiv--911d3";