/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
import classNames from "classnames";
import React, { forwardRef } from "react";

import "./button.scss";
import { Link } from "gatsby";

const Button = forwardRef(
  (
    { children, className, danger, type = "button", href, secondary, ...props },
    ref,
  ) => {
    if (href) {
      return (
        <Link
          ref={ref}
          className={classNames("btn", className, {
            secondary,
          })}
          danger={danger}
          href={href}
          to={href}
          {...props}
        >
          {children}
        </Link>
      );
    }
    return (
      <button
        ref={ref}
        type={type}
        className={`${className} btn`}
        danger={danger}
        {...props}
      >
        {children}
      </button>
    );
  },
);

export default Button;
