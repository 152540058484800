import React, { useContext, useState } from "react";
import { Link } from "gatsby";
import toast from "react-hot-toast";
import { navigate } from "@reach/router";
import API from "../../services/api";
import {
  trackEvent,
  getUtmParamsForForm,
  isRestrictedEmail,
} from "../../utils";
import Button from "../button/button";
import * as styles from "./contactSales.module.scss";
import { GOOGLE_ADS_CONVERSION_TAG } from "../../services/analytics";
import { GlobalContext } from "../../context/global-context";
import CountryCodeSelector from "../countryCodeSelector/countryCodeSelector";

export const LEAD_TYPE = {
  salesCallback: "sales-callback",
  demoCallback: "demo-callback",
};

function LeadForm({ leadType, hideReqField, isUSRegion }) {
  const context = useContext(GlobalContext);

  const [email, setEmail] = useState(context?.email || "");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [requirement, setRequirement] = useState("");
  const [country, setCountry] = useState(context.country);

  const validPhoneLength = () => {
    return country.phoneNumberLength.includes(phone.length.toString());
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const errors = {};

    errors.name = !name;
    errors.email = isRestrictedEmail(email);
    errors.company = !company;
    errors.phone = !phone || Number.isNaN(Number(phone)) || !validPhoneLength();

    const hasErrors = Object.values(errors).some((x) => x);
    setError(errors);
    const { phonePrefix } = country;
    if (!hasErrors) {
      setLoading(true);
      const data = {
        phone: phonePrefix + "/" + phone,
        company_email: email,
        company_name: company,
        other_details: {
          user_name: name,
          requirement,
          request_type: leadType,
          utm_params: getUtmParamsForForm(),
          timestamp: new Date(),
        },
      };
      API.sendLead(data)
        .then(() => {
          trackEvent(
            "Lead generated from contact sales page",
            "sales-page-leads",
          );

          if (window && window.gtag) {
            window.gtag("event", "conversion", {
              send_to:
                leadType === LEAD_TYPE.salesCallback
                  ? GOOGLE_ADS_CONVERSION_TAG.SALES_CALLBACK_FORM
                  : GOOGLE_ADS_CONVERSION_TAG.BOOK_DEMO_FORM,
            });
          }

          const [firstName, ...lastNameArray] = name.trim().split(" ");
          const lastName = lastNameArray.join(" ");
          const meetingLink = isUSRegion
            ? process.env.GATSBY_HUBSPOT_US_MEETING_LINK
            : process.env.GATSBY_HUBSPOT_MEETING_LINK;

          navigate(
            `${meetingLink}?email=${email}&firstname=${firstName}&lastname=${lastName}`,
          );
          // reset context email
          context?.setEmail("");
        })
        .catch((err) => {
          if (err.error) {
            toast.error(err.error.message);
          } else {
            toast.error("An error occured! Please try again...");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className={styles.leadFormWrapper} onSubmit={handleFormSubmit}>
      {isSubmitted ? (
        <div className={styles.leadSubmitted}>
          <h2>We are eager to speak to you.</h2>
          <p>
            Our team will get back to you as soon as possible. See how Procol is
            helping enterprise businesses automate Procurement and bring cost
            savings!
          </p>
          <Link to="/case-study/">
            View Case Studies{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
              height={20}
              width={20}
            >
              <path
                fillRule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>{" "}
          </Link>
        </div>
      ) : (
        <div className={styles.leadForm}>
          <form>
            <div className={styles.row}>
              <div>
                <label htmlFor="name">
                  Name <span className={styles.reqStar}>*</span>
                  <input
                    type="text"
                    required
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    id="name"
                    name="name"
                    placeholder="Enter your name"
                  />
                </label>
                {error.name && <p role="alert">Please enter a name</p>}
              </div>

              <div>
                <label htmlFor="name">
                  Email <span className={styles.reqStar}>*</span>
                  <input
                    type="email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    name="email"
                    id="email"
                    placeholder="Enter your work email"
                  />
                </label>
                {error.email && (
                  <p role="alert">Please enter your valid work email</p>
                )}
              </div>
            </div>
            <div className={styles.row}>
              <div>
                <label htmlFor="name">
                  Phone Number <span className={styles.reqStar}>*</span>
                  <div className={styles.phoneContainer}>
                    <CountryCodeSelector
                      setSelectedCountry={setCountry}
                      country={country}
                    />
                    <input
                      required
                      type="tel"
                      value={phone}
                      placeholder="Enter your phone number"
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </label>
                {error.phone && <p role="alert">Please enter a valid number</p>}
              </div>

              <div>
                <label htmlFor="name">
                  Company Name <span className={styles.reqStar}>*</span>
                  <input
                    type="text"
                    required
                    onChange={(e) => setCompany(e.target.value)}
                    value={company}
                    name="company"
                    placeholder="Enter company name"
                  />
                </label>
                {error.company && (
                  <p role="alert">Please enter a company name</p>
                )}
              </div>
            </div>
            {!hideReqField && (
              <div>
                <label htmlFor="name">
                  Requirements
                  <textarea
                    type="text"
                    value={requirement}
                    placeholder="Tell us what you need"
                    onChange={(e) => setRequirement(e.target.value)}
                    id="requirement"
                  />
                </label>
              </div>
            )}
            <Button disabled={loading} type="submit">
              Submit
            </Button>
          </form>
        </div>
      )}
    </div>
  );
}

export default LeadForm;
