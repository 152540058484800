/* eslint-disable  */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import { GlobalContext } from "../../../context/global-context";
import Logo from "../../logo";
import "./styles.scss";
import * as styles from "./styles.module.scss";
import { useLeadFormModal } from "../../../hooks";
import { LEAD_TYPE } from "../../contactSales/leadForm";

const menu = [
  {
    title: "Solutions",
    key: "solutions",
    subMenu: [
      // { title: "ProCapital", link: "/capital/" },
      // { title: "ProSource", link: "/prosource/" },
      { title: "Sourcing Pro", link: "/sourcing-pro/" },
      { title: "Strategic Sourcing", link: "/strategic-sourcing/" },
      { title: "Indirect Sourcing", link: "/indirect-sourcing/" },
      { title: "Capex Sourcing", link: "/capex-sourcing/" },
      { title: "eAuctions Pro", link: "/eauctions-pro/" },
      {
        title: "Supplier Management",
        link: "/supplier-relationship-management-software/",
      },
      { title: "Intake Management", link: "/intake-management/" },
      { title: "Approval Workflows", link: "/approval-workflows/" },
      { title: "Reports and Analytics", link: "/reports-and-analytics/" },
      { title: "Integrations", link: "/integrations/" },
    ],
  },
  // {
  //   title: "Industries",
  //   key: "industries",
  //   subMenu: [
  //     { title: "Manufacturing", link: "/manufacturing/" },
  //     { title: "Food and Beverages", link: "/food-and-beverages/" },
  //   ],
  // },
  // {
  //   title: "Categories",
  //   key: "categories",
  //   subMenu: [
  //     { title: "Agriculture", link: "/agriculture/" },
  //     { title: "Capex", link: "/capex/" },
  //     { title: "Direct Materials", link: "/direct-materials/" },
  //     { title: "Logistics", link: "/logistics/" },
  //     { title: "Services", link: "/services/" },
  //     { title: "Scrap Management", link: "/scrap-management/" },
  //   ],
  // },
  {
    title: "Resources",
    key: "resources",
    subMenu: [
      {
        title: "Blog",
        link: "/blog/",
      },
      {
        title: "Case Study",
        link: "/case-study/",
      },
      {
        title: "Savings Calculator",
        link: "/#roi",
      },
      {
        title: "Become a Partner",
        link: "/channel-partner/",
      },
      {
        title: "Glossary",
        link: "/glossary/",
      },
    ],
  },
  // {
  //   title: "More",
  //   key: "more",
  //   subMenu: [
  //     {
  //       title: "Become a Partner",
  //       link: "/channel-partner/",
  //     },
  //   ],
  // },
];

function MobileHeader({ isWhite, isHome, location }) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isNavOpen, setNavOpen] = useState(false);
  const [homeRoute, setHomeRoute] = useState("/");
  const context = useContext(GlobalContext);
  const navBG = context && context.navBG;
  const setLeadModalVisible = useLeadFormModal();

  const clacHeightChange = () => {
    const selectedItemCount =
      selectedItem &&
      menu.find((item) => item.title === selectedItem).subMenu.length;
    const selectedItemHeight = selectedItemCount * 36.7;

    const menuCount = menu.length + 2; // 2 for contact sales and pricing;
    const menuHeight = menuCount * 44.7;

    return isNavOpen ? menuHeight + selectedItemHeight + 16 : 0; // + 16 for padding
  };

  const handleOpenHeader = (menuItem) => {
    if (menuItem === selectedItem) {
      setSelectedItem(null);
    } else {
      setSelectedItem(menuItem);
    }
  };
  const handleNavigate = (url) => {
    window.location.href = location.origin + url;
  };

  const allowBg = () => {
    if (isHome) return navBG;
    return !isWhite || navBG;
  };

  useEffect(() => {
    setNavOpen(false);
  }, [location.href]);

  useEffect(() => {
    setHomeRoute(location.origin);
  }, [location]);

  return (
    <header
      className={classNames("header__mobile", {
        "nav-bg": allowBg(),
        [styles.whiteBg]: isNavOpen,
      })}
    >
      <nav
        className={classNames("navbar", {
          isWhite: !navBG && isWhite,
        })}
      >
        <button
          type="button"
          className="nav_logo"
          aria-label="link for home page"
          onClick={() => {
            window.location.href = homeRoute;
          }}
        >
          <Logo isWhite={!navBG && !isNavOpen && isWhite} />
        </button>
        <div className="cta-actions">
          <Link
            to="/request-demo/"
            className="btn"
            onClick={setLeadModalVisible}
          >
            Free Demo
          </Link>
          <div className={`hamburger ${isNavOpen && "app-header_active"} `}>
            <button
              type="button"
              className="app-header__burger"
              onClick={() => {
                setNavOpen(!isNavOpen);
                setSelectedItem(null);
              }}
              aria-label="open menu"
            >
              <div />
              <div />
            </button>
          </div>
        </div>
      </nav>

      <div
        className={classNames(styles.mobileDropdownNavWrapper, {
          [styles.isSubMenuOpen]: selectedItem,
          [styles.isMenuOpen]: isNavOpen,
        })}
        style={{
          height: clacHeightChange(),
        }}
      >
        <ul className={styles.mobileDropdownNav}>
          {menu.map((item) => (
            <li className={styles.mobileDropdownNavItem}>
              <button
                key={item.title}
                onClick={() => handleOpenHeader(item.title)}
                type="button"
              >
                {item.title}
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 10.6667L4 6.66675H12L8 10.6667Z"
                    fill="currentColor"
                  />
                </svg>
              </button>

              {item.title === selectedItem && (
                <ul className={styles.navSubMenuWrapper}>
                  {menu.map((subMenuItem) =>
                    subMenuItem.title === selectedItem
                      ? subMenuItem.subMenu.map((i) => (
                          <a onClick={() => handleNavigate(i.link)}>
                            <li>{i.title}</li>
                          </a>
                        ))
                      : null,
                  )}
                </ul>
              )}
            </li>
          ))}
          <li className={styles.mobileDropdownNavItem}>
            <a
              onClick={() => handleNavigate("/contact-sales/")}
              className={styles.directLink}
            >
              Contact Sales
            </a>
          </li>
          <li className={styles.mobileDropdownNavItem}>
            <a
              className={styles.directLink}
              href="https://www.procol.io/pricing"
            >
              Pricing
            </a>
          </li>
        </ul>
      </div>

      {/* {isNavOpen && (
        <div className="menuContainer">
          <div
            className="inactive"
            onClick={() => {
              setNavOpen(!isNavOpen);
            }}
            tabIndex="-1"
            onKeyDown={(e) => {
              if (e.key === "Enter") handleCloseHeader();
            }}
            type="button"
            aria-hidden="true"
          />
          <motion.div
            className={`mobile-menu ${isNavOpen && "isActive"}`}
            initial={{ x: "200%" }}
            animate={{ x: "0%" }}
            transition={{
              duration: "0.5",
              bounce: 0,
              type: "spring",
            }}
          >
            <div className="navGrid">
              {selectedItem ? (
                <div className="subMenuWrapper">
                  <div className="subMenuHeader">
                    <button
                      onClick={() => handleCloseHeader()}
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleCloseHeader();
                      }}
                      type="button"
                    >
                      <img
                        src={rightArrow}
                        alt="title"
                        aria-hidden="true"
                        className="arrowLeft"
                      />
                      <span>{selectedItem}</span>
                    </button>
                  </div>
                  <ul className="subMenuItemWrapper">
                    {menu.map((item) =>
                      item.title === selectedItem
                        ? item.subMenu.map((i, index) => (
                            <Link to={i.link}>
                              <motion.li
                                initial={{ x: "-100%", opacity: 0.5 }}
                                animate={{ x: "0%", opacity: 1 }}
                                transition={{
                                  delay: index * 0.08,
                                  bounce: 0,
                                  type: "spring",
                                  duration: 0.5,
                                }}
                              >
                                {i.title}
                              </motion.li>
                            </Link>
                          ))
                        : null,
                    )}
                  </ul>
                </div>
              ) : (
                <ul className="menu-wrapper">
                  {menu.map((item) => (
                    <li className="menu-item">
                      <button
                        key={item.title}
                        onClick={() => handleOpenHeader(item.title)}
                        type="button"
                      >
                        {item.title}
                        <div className="headerIcon">
                          <img src={rightArrow} alt="left" />
                        </div>
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="menuAction">
              <div>
                <Link
                  to="/request-demo/"
                  className="btn--blue"
                  onClick={setLeadModalVisible}
                >
                  Free Demo
                </Link>
              </div>
              <div>
                <Link
                  to="/contact-sales/"
                  className="secondaryAction"
                  onClick={(e) => {
                    setLeadModalVisible(e, true, LEAD_TYPE.salesCallback);
                  }}
                >
                  Contact Sales
                </Link>
              </div>
            </div>
          </motion.div>
        </div>
      )} */}
    </header>
  );
}

export default MobileHeader;
