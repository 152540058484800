import { getCookie } from "../utils";

/* eslint-disable prefer-destructuring */
export const GOOGLE_ADS_BASE_TAG = process.env.GATSBY_GADS_TAG_ID;

export const GOOGLE_ADS_CONVERSION_TAG = {
  BOOK_DEMO_FORM: `${GOOGLE_ADS_BASE_TAG}/TsXHCNP-x8QCEOquibYB`,
  ROI_FORM: `${GOOGLE_ADS_BASE_TAG}/gHvNCOCIo9wYEOquibYB`,
  CASE_STUDY_FORM: `${GOOGLE_ADS_BASE_TAG}/z9HICJOdr9wYEOquibYB`,
  SALES_CALLBACK_FORM: `${GOOGLE_ADS_BASE_TAG}/u88HCMHBodwYEOquibYB`,
};

const GATSBY_GA_TAG_ID = process.env.GATSBY_GA_TAG_ID;
const GATSBY_GADS_TAG_ID = process.env.GATSBY_GADS_TAG_ID;

export const GTAG_IDS = [
  GATSBY_GA_TAG_ID, // Google Analytics Tag
  GATSBY_GADS_TAG_ID, // Google Ads Tag
].filter((id) => id); // filter so there won't be sparse array

export const sendGAPageView = (location) => {
  // https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-google-gtag/src/gatsby-browser.js
  if (
    process.env.NODE_ENV !== `production` ||
    typeof window.gtag !== `function`
  ) {
    return null;
  }

  // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)
  const sendPageView = () => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined;
    window.gtag(`event`, `page_view`, { page_path: pagePath });
  };

  if (`requestAnimationFrame` in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => setTimeout(sendPageView, 0));
    });
  } else {
    // Delay by 32ms to simulate 2 requestOnAnimationFrame calls
    setTimeout(sendPageView, 32);
  }

  return null;
};

export const getConsentScript = () => `
	const getCookie = (name) => {
			const allCookies = document.cookie.split(";");
			let cookieValue = null;
			allCookies.forEach((currentCookie) => {
				const updatedCookie = currentCookie.trim();
				if (updatedCookie.startsWith(name+"=")) {
					const value = updatedCookie.replace(name+"=", "");
					cookieValue = JSON.parse(value);
				}
			});
			return cookieValue;
	};

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  window.gtag("consent", "default", {
    ad_storage: getCookie("ads_track_allowed") || "granted",
    analytics_storage: getCookie("analytics_track_allowed") || "granted",
  });
  window.gtag("consent", "update", {
    ad_storage: getCookie("ads_track_allowed") || "granted",
    analytics_storage: getCookie("analytics_track_allowed") || "granted",
  });
	`;

export const loadHubspotAnalytics = () => {
  if (getCookie("analytics_track_allowed") === "denied") {
    if (window._hsp) {
      window._hsp.push(["revokeCookieConsent"]);
    }
    if (window._hsq) {
      window._hsq.push(["doNotTrack", { track: true }]);
    }
  }
};
