import React from "react";
import classNames from "classnames";
import * as styles from "./cookieBar.module.scss";

const ButtonGroup = ({ onChange, value }) => (
  <div className={styles.btnGroup}>
    <div className={styles.btnGroupWrapper}>
      <button
        type="button"
        className={classNames(styles.button, styles.btnNo)}
        onClick={() => onChange(false)}
        data-active={!value}
      >
        No
      </button>
      <button
        type="button"
        className={classNames(styles.button, styles.btnYes)}
        onClick={() => onChange(true)}
        data-active={value}
      >
        Yes
      </button>
    </div>
  </div>
);

export default ButtonGroup;
