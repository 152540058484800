import {
  REGION,
  RESTRICTED_EMAIL_DOMAINS,
} from "../content/industriesPages/constants";

function SVS_B(eAmt, where) {
  if (where === "center" || where === "") {
    window.scrollBy(0, eAmt / 2);
  }
  if (where === "top") {
    window.scrollBy(0, eAmt);
  }
}

export function smoothVerticalScrolling(e, time, where, id) {
  let element;

  if (e) {
    element = e;
  } else if (id) {
    element = document.getElementById(id);
  }

  const eTop = element.getBoundingClientRect().top;
  const eAmt = eTop / 100;
  let curTime = 0;

  while (curTime <= time) {
    window.setTimeout(SVS_B, curTime, eAmt, where);
    curTime += time / 100;
  }
}

export const isArray = (array) => Array.isArray(array) && array.length > 0;

// export const downloadFile = (uri, name) => {
// 	const link = document.createElement("a");
// 	link.download = name;
// 	link.href = uri;
// 	link.target = "_blank";
// 	document.body.appendChild(link);
// 	link.click();
// 	document.body.removeChild(link);
// };

export const downloadFile = (uri, name) => {
  fetch(uri, {
    method: "GET",
    headers: {
      "Content-Type": "application/pdf",
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.pdf`);
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
};

export const trackEvent = (label, category) => {
  if (window && window.gtag) {
    window.gtag("event", "click", {
      // string - required - The object that was interacted with (e.g.video)
      event_category: category || "CTA Button",
      // string - required - Type of interaction (e.g. 'play')
      action: "click",
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      event_label: label,
    });
  }
};

export const trackOutboundLink = (url) => {
  if (window && window.gtag) {
    window.gtag("event", "click", {
      event_category: "outbound",
      event_label: url,
      transport_type: "beacon",
    });
  }
};

export const comma = (number) =>
  new Intl.NumberFormat("en-IN", {
    maximumFractionDigits: 2,
  }).format(number);

export const isValidString = (value) => {
  if (!value || value.trim().length === 0) {
    return true;
  }
  return false;
};
export const isRestrictedEmail = (email = "") => {
  const isValidEmailString = /\S+@\S+\.\S+/.test(email);

  if (isValidEmailString) {
    const isRestrictedEmailFlag = RESTRICTED_EMAIL_DOMAINS.some(
      (restrictedDomain) =>
        email.toLowerCase().includes(`@${restrictedDomain.toLowerCase()}.`),
    );
    return isRestrictedEmailFlag;
  }

  return true;
};

export const isObject = (obj) =>
  obj instanceof Object && Object.keys(obj).length > 0;

export const getCookie = (name) => {
  if (typeof document !== "undefined") {
    const allCookies = document.cookie.split(";");

    let cookieValue = null;
    allCookies.forEach((currentCookie) => {
      const updatedCookie = currentCookie.trim();
      if (updatedCookie.startsWith(`${name}=`)) {
        const value = updatedCookie.replace(`${name}=`, "");
        cookieValue = JSON.parse(value);
      }
    });

    return cookieValue;
  }
  return null;
};

export const setCookie = ({ name, value, expiry = 7 }) => {
  if (typeof document !== "undefined") {
    let expires = "";
    if (expiry) {
      const date = new Date();
      date.setTime(date.getTime() + expiry * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${JSON.stringify(value)}${expires}; path=/`;
  }
};

export const getUtmParams = (url) => {
  const utmParams = {};
  const queryParams = new URLSearchParams(url);

  queryParams.forEach((value, key) => {
    if (key.startsWith("utm_")) {
      utmParams[key] = value;
    }
  });

  return utmParams;
};

export const LANDING_LOCATION = "landingLocation";

export const getUtmParamsForForm = () => {
  const utmParams = getCookie("utm_params") || {};
  const { pathname, href } = window.location || {};
  return {
    ...utmParams,
    ...JSON.parse(sessionStorage.getItem(LANDING_LOCATION)),
    utm_submission_path: pathname,
    utm_submission_url: href,
  };
};

export const appendUtmParamsInUrl = (url) => {
  const storedUtmParams = getCookie("utm_params");
  const updatedUrl = new URL(url);

  if (isObject(storedUtmParams)) {
    Object.keys(storedUtmParams).forEach((param) => {
      updatedUrl.searchParams.set(`${param}`, storedUtmParams[param]);
    });
  } else {
    updatedUrl.searchParams.set("utm_source", "default");
  }
  return updatedUrl.toString();
};

export const getRegion = () => {
  if (typeof window === "undefined") {
    return null;
  }
  const urlParams = new URLSearchParams(window.location.search);
  const region = urlParams.get(REGION.KEY);
  const regionData = window.localStorage.getItem(REGION.KEY);
  return region || regionData;
};

export const getHomePageLink = () => {
  const region = getRegion();
  if (region === REGION.VALUES.us) return "/us/";
  return "/";
};

export const setRegion = () => {
  if (typeof window !== "undefined") {
    const params = new URLSearchParams(window.location.search);
    const region = params.get(REGION.KEY);
    if (region) window.localStorage.setItem(REGION.KEY, region);
  }
};

export const setLandingLocation = () => {
  if (!sessionStorage.getItem(LANDING_LOCATION)) {
    const { pathname, href } = window.location || {};

    window.sessionStorage.setItem(
      LANDING_LOCATION,
      JSON.stringify({
        utm_landing_path: pathname,
        utm_landing_url: href,
      }),
    );
  }
};

export const COOKIE_BANNER_KEY = "consentBannerData";

export const getCookiePermission = () => {
  if (typeof window === "undefined") {
    return null;
  }

  const cookieBannerData = localStorage.getItem(COOKIE_BANNER_KEY);

  const cookiePermission = cookieBannerData
    ? JSON.parse(cookieBannerData).permission
    : false;

  return cookiePermission;
};

export const clearCookies = ({ isAdsAllowed, isAnalyticsAllowed }) => {
  if (typeof document !== "undefined") {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i += 1) {
      const cookieName = cookies[i].split("=")[0];
      if (!isAdsAllowed && cookieName.startsWith("_gcl")) {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
      }

      if (!isAnalyticsAllowed) {
        if (
          cookieName.startsWith("__hs") ||
          cookieName.startsWith("hubspot") ||
          cookieName.startsWith("_ga")
        ) {
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
          window._hsp.push(["revokeCookieConsent"]);
          window._hsq.push(["doNotTrack", { track: true }]);
        }
      }
    }
  }
};

export const setTrackingPermissions = ({
  isAdsAllowed,
  isAnalyticsAllowed,
}) => {
  const adsPermission = isAdsAllowed ? "granted" : "denied";
  const analyticsPermission = isAnalyticsAllowed ? "granted" : "denied";
  window.gtag("consent", "update", {
    ad_storage: adsPermission,
    analytics_storage: analyticsPermission,
  });

  setCookie({
    name: "ads_track_allowed",
    value: adsPermission,
    expiry: 365,
  });
  setCookie({
    name: "analytics_track_allowed",
    value: analyticsPermission,
    expiry: 365,
  });
};
