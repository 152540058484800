import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Toaster } from "react-hot-toast";
import "normalize.css";
import "./layout.scss";
import Footer from "../footer";
import GlobalContextProvider from "../../context/global-context";
import DesktopHeader from "../header/desktop/desktop-header";
import MobileHeader from "../header/mobile/mobile-header";
import SecondaryFooter from "../footer/secondaryFooter";
import Hubspot from "../../services/hubspot";
import ContactSalesModal from "../contactSales/contactSalesModal";
import CookieConsentPopup from "../cookieConsentPopup";
import { setLandingLocation, setRegion } from "../../utils";
import { loadHubspotAnalytics } from "../../services/analytics";

const duration = 0.25;

const variants = {
  initial: {
    opacity: 0,
    y: -15,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    y: 15,
    transition: { duration },
  },
};

function Layout({ children, location }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isWhite, setIsWhite] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const [isProDiscoverPage, setIsProDiscoverPage] = useState(false);
  const [isProCapitalPage, setIsProCapitalPage] = useState(false);
  const [isProSourcePage, setIsProSourcePage] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (location) {
      setLandingLocation();
    }
  }, [location]);

  useEffect(() => {
    setRegion();
    const white = [
      "/about",
      "/about/",
      "/jobs",
      "/jobs/",
      "/industries",
      "/industries/",
      "/reseller",
      "/reseller/",
    ].includes(location.pathname);

    const show = ["/careers", "/careers/"].includes(location.pathname);

    const isProdiscoverPage = [
      "/prodiscover/contact",
      "/prodiscover/contact/",
      "/prodiscover",
      "/prodiscover/",
      "/prodiscover/supplier/",
      "/prodiscover/supplier",
    ].includes(location.pathname);

    const isProCapital = [
      "/capital",
      "/capital/",
      "/capital/contact",
      "/capital/contact/",
    ].includes(location.pathname);

    const isProsourcePage = ["/prosource/", "/prosource"].includes(
      location.pathname,
    );

    setShowFooter(!show);
    setIsProDiscoverPage(isProdiscoverPage);
    setIsProCapitalPage(isProCapital);
    setIsProSourcePage(isProsourcePage);
    setIsWhite(white);
  }, [location]);

  useEffect(() => {
    loadHubspotAnalytics();
  }, []);

  return (
    <GlobalContextProvider>
      <div className="wrapper">
        <main>
          <DesktopHeader
            isWhite={isWhite}
            isProDiscoverPage={isProDiscoverPage}
            isProCapitalPage={isProCapitalPage}
            isProSourcePage={isProSourcePage}
            isHome={["/", "/us/"].includes(location.pathname)}
            location={location}
          />
          <MobileHeader
            isWhite={isWhite}
            isProDiscoverPage={isProDiscoverPage}
            isProCapitalPage={isProCapitalPage}
            isProSourcePage={isProSourcePage}
            isHome={["/", "/us/"].includes(location.pathname)}
            location={location}
          />
          <AnimatePresence>
            <motion.div
              key={location && location.pathname}
              variants={isLoaded && variants}
              initial="initial"
              animate="enter"
              exit="exit"
            >
              {children}
              {showFooter && (
                <>
                  <Footer location={location} />
                  <SecondaryFooter />
                </>
              )}
            </motion.div>
          </AnimatePresence>
        </main>
      </div>
      <ContactSalesModal />

      <CookieConsentPopup />

      <Hubspot />
      <Toaster />
    </GlobalContextProvider>
  );
}

export default Layout;
