/* eslint-disable  */
import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import logo from "../../../static/images/procol-logo-vertical.svg";
import facebook from "../../../static/images/facebook.svg";
import linkedin from "../../../static/images/linkedin.svg";
import twitter from "../../../static/images/twitter.svg";
import youtube from "../../../static/images/youtube.svg";
import email from "../../../static/images/email.svg";
import * as styles from "./footer.module.scss";

function Footer({ location }) {
  const handleNavigate = (url) => {
    window.location.href = location.origin + url;
  };
  const data = useStaticQuery(graphql`
    {
      recentPosts: allContentfulBlog(sort: { date: DESC }, limit: 3) {
        edges {
          node {
            slug
            title
          }
        }
      }
      caseStudy: allContentfulCaseStudy(sort: { date: DESC }, limit: 3) {
        edges {
          node {
            slug
            title
          }
        }
      }
    }
  `);
  return (
    <footer className={styles.footerWrapper}>
      <div className="container">
        <div className={styles.footerInner}>
          {/* <div className={styles.aboutCompany}>
            <img src={logo} alt="procol logo" />
            <p>
              Procol is the world’s fastest growing procurement platform that
              manages millions of transactions across a trusted network of
              buyers and sellers.
            </p>
          </div> */}
          <div className={styles.linksWrapper}>
            <div className={styles.linksList}>
              <ul className={styles.linksBlock}>
                <p className={styles.linksHead}>Company</p>

                <li>
                  <Link to="/about/">About Us</Link>
                </li>
                <li>
                  <a onClick={() => handleNavigate("/contact-sales")}>
                    Contact Us
                  </a>
                </li>
                <li>
                  <Link to="/careers/">Careers at Procol</Link>
                </li>
              </ul>
              <ul className={styles.linksBlock}>
                <p className={styles.linksHead}>Solutions</p>

                <li>
                  <Link to="/sourcing-pro/">Sourcing Pro</Link>
                </li>
                <li>
                  <Link to="/indirect-sourcing/">Indirect Sourcing</Link>
                </li>
                <li>
                  <Link to="/intake-management/">Intake Management</Link>
                </li>
              </ul>

              <ul className={styles.linksBlock}>
                <p className={styles.linksHead}>Blogs</p>

                {data.recentPosts.edges.map(({ node }) => (
                  <li key={node.slug}>
                    <Link to={`/blog${node.slug}`}>{node.title}</Link>
                  </li>
                ))}
              </ul>
              <ul className={styles.linksBlock}>
                <p className={styles.linksHead}>Case Study</p>

                {data.caseStudy.edges.map(({ node }) => (
                  <li key={node.slug}>
                    <Link to={`/case-study${node.slug}`}>{node.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.linksInfo}>
              {/* <div className={styles.addressInfo}>
                <div className={styles.linksHead}>Corporate office</div>
                <p>
                  Procol HQ, Unit 404, Tower&nbsp;C,
                  Unitech&nbsp;Cyber&nbsp;Park, Sector 39, Gurugram, 122003
                </p>
              </div> */}
              <ul className={styles.socialLinksWrapper}>
                <div className={styles.linksHead}>Get in touch</div>
                <li className={styles.email}>
                  <a href="mailto:partner@procol.in">
                    <img src={email} alt="procol email" />
                    partner@procol.in
                  </a>
                </li>
                <ul className={styles.socialLinksList}>
                  <li>
                    <a
                      href="https://twitter.com/Procol_"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={twitter} alt="Procol twitter page" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/procoltech/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={linkedin} alt="Procol linkedin page" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCm7isOpdWcmD-6ne3Tp32tw"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={youtube} alt="Procol youtube page" />
                    </a>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
