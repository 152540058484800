import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import * as styles from "./cookieBar.module.scss";
import { clearCookies, getCookie } from "../../utils";
import ButtonGroup from "./buttonGroup";
import ArrowRight from "../../../static/images/landing/arrow-right.svg";

const duration = 0.2;

const variants = {
  initial: {
    opacity: 0,
    y: 15,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration,
      delay: 0,
      when: "beforeChildren",
    },
  },
};

const cookieData = {
  strictlyNecessary: [
    {
      provider: "Procol",
      description:
        "Stores the user's cookie consent state for the current domain",
      domain: "procol.io",
      privacyPolicy: "https://procol.io/legal/privacy",
    },
  ],
  analytics: [
    {
      provider: "Google Analytics",
      description:
        "Collects information about how visitors use our website. We use the information to improve our website.",
      domain: "google.com",
      privacyPolicy: "https://policies.google.com/privacy",
    },
    {
      provider: "HubSpot",
      description:
        "Collects information about how visitors use our website. We use the information to improve our website.",
      domain: "hubspot.com",
      privacyPolicy: "https://legal.hubspot.com/privacy-policy",
    },
  ],
  advertising: [
    {
      provider: "Google Ads",
      description:
        "Used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.",
      domain: "google.com",
      privacyPolicy: "https://policies.google.com/privacy",
    },
  ],
};

const CookieDetailItem = ({ item }) => (
  <div className={styles.detailItem}>
    <ul>
      <li>
        <span className={styles.label}>Provider</span>
        <span className={styles.value}>{item.provider}</span>
      </li>
      <li>
        <span className={styles.label}>Description</span>
        <span className={styles.value}>{item.description}</span>
      </li>
      <li>
        <span className={styles.label}>Domain</span>
        <span className={styles.value}>{item.domain}</span>
      </li>
      <li>
        <span className={styles.label}>Privacy Policy</span>
        <a
          href={item.privacyPolicy}
          target="_blank"
          rel="noreferrer"
          className={styles.value}
        >
          {item.privacyPolicy}
        </a>
      </li>
    </ul>
  </div>
);

const CookieSettingsModal = ({ visible, setVisible, hidePopup }) => {
  const adCookie = getCookie("ads_track_allowed");
  const analyticsCookie = getCookie("analytics_track_allowed");

  const [analyticsCookiesSelected, setAnalyticsCookiesSelected] =
    useState(true);
  const [adsCookiesSelected, setAdsCookiesSelected] = useState(true);

  const [currentOpen, setCurrentOpen] = useState([]);

  const handleClose = () => {
    setVisible(false);
  };

  const handleCookieSettingChanges = () => {
    setVisible(false);
    clearCookies({
      isAdsAllowed: adsCookiesSelected,
      isAnalyticsAllowed: analyticsCookiesSelected,
    });
    hidePopup(adsCookiesSelected, analyticsCookiesSelected);
  };

  const handleChangeOpen = (name) => {
    if (currentOpen.includes(name)) {
      setCurrentOpen(currentOpen.filter((item) => item !== name));
    } else {
      setCurrentOpen([...currentOpen, name]);
    }
  };

  useEffect(() => {
    if (visible) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    return () => document.body.classList.remove("modal-open");
  }, [visible]);

  useEffect(() => {
    if (analyticsCookie) {
      setAnalyticsCookiesSelected(analyticsCookie === "granted");
    }
    if (adCookie) {
      setAdsCookiesSelected(adCookie === "granted");
    }
  }, [analyticsCookie, adCookie]);

  if (!visible) {
    return null;
  }
  return (
    <motion.div
      key="cookieSettings"
      variants={variants}
      initial="initial"
      animate="enter"
      className={styles.cookieSettingsOverlay}
    >
      <div className={styles.cookieSettingsModal}>
        <div className={styles.settingsHeader}>
          <h2>Cookie Settings</h2>
          <button
            className={styles.closeBtn}
            onClick={handleClose}
            type="button"
            aria-label="Close"
          >
            <svg
              width="1.2em"
              height="1.2em"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
            >
              <path
                d="M5.293 5.293a1 1 0 0 1 1.414 0L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 0-1.414Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>

        <p>
          We use cookies to ensure the basic functionalities of the website and
          enhance your online experience. You can choose for each category to
          opt in/out. For more details read the full{" "}
          <Link
            to="/legal/privacy"
            onClick={handleClose}
            style={{ color: "#1375e4" }}
          >
            privacy policy
          </Link>
          .
        </p>
        <div className={styles.settingWrapper}>
          <div className={styles.setting}>
            <div className={styles.settingName}>
              <div
                className={styles.name}
                onClick={() => handleChangeOpen("strictlyNecessary")}
                tabIndex={0}
                role="button"
                onKeyDown={(e) =>
                  e.key === "Enter" && handleChangeOpen("strictlyNecessary")
                }
              >
                <img
                  src={ArrowRight}
                  data-active={currentOpen.includes("strictlyNecessary")}
                  alt="Read_more_image"
                />{" "}
                Strictly Necessary Cookies
              </div>

              <div className={styles.activeTag}>Active</div>
            </div>
            <div
              className={styles.cookieDetails}
              data-active={currentOpen.includes("strictlyNecessary")}
            >
              {cookieData.strictlyNecessary.map((item) => (
                <CookieDetailItem item={item} />
              ))}
            </div>
          </div>
          <div className={styles.setting}>
            <div className={styles.settingName}>
              <div
                className={styles.name}
                onClick={() => handleChangeOpen("analytics")}
                tabIndex={0}
                role="button"
                onKeyDown={(e) =>
                  e.key === "Enter" && handleChangeOpen("analytics")
                }
              >
                <img
                  src={ArrowRight}
                  data-active={currentOpen.includes("analytics")}
                  alt="Read_more_image"
                />{" "}
                Analytics Cookies
              </div>
              <ButtonGroup
                onChange={(val) => setAnalyticsCookiesSelected(val)}
                value={analyticsCookiesSelected}
              />
            </div>
            <div
              className={styles.cookieDetails}
              data-active={currentOpen.includes("analytics")}
            >
              {cookieData.analytics.map((item) => (
                <CookieDetailItem item={item} />
              ))}
            </div>
          </div>
          <div className={styles.setting}>
            <div className={styles.settingName}>
              <div
                className={styles.name}
                onClick={() => handleChangeOpen("advertising")}
                tabIndex={0}
                role="button"
                onKeyDown={(e) =>
                  e.key === "Enter" && handleChangeOpen("advertising")
                }
              >
                <img
                  src={ArrowRight}
                  data-active={currentOpen.includes("advertising")}
                  alt="Read_more_image"
                />
                Advertising Cookies
              </div>
              <ButtonGroup
                onChange={(val) => setAdsCookiesSelected(val)}
                value={adsCookiesSelected}
              />
            </div>
            <div
              className={styles.cookieDetails}
              data-active={currentOpen.includes("advertising")}
            >
              {cookieData.advertising.map((item) => (
                <CookieDetailItem item={item} />
              ))}
            </div>
          </div>
        </div>
        <button
          type="button"
          className={styles.saveBtn}
          onClick={handleCookieSettingChanges}
        >
          Accept and Save Changes
        </button>
      </div>
    </motion.div>
  );
};

export default CookieSettingsModal;
