import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { AnimatePresence, motion } from "framer-motion";
import * as styles from "./cookieBar.module.scss";
import { getCookie, setTrackingPermissions } from "../../utils";
import CookieSettingsModal from "./cookieSettings";

const duration = 0.25;

const variants = {
  initial: {
    opacity: 0,
    y: 15,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    y: 15,
    transition: { duration },
  },
};

function CookieConsentPopup() {
  const [showPopup, setShowPopup] = useState(false);
  const [showCookieSettings, setShowCookieSettings] = useState(false);

  const hidePopup = (isAdsAllowed, isAnalyticsAllowed) => {
    setShowPopup(false);
    setTrackingPermissions({
      isAdsAllowed,
      isAnalyticsAllowed,
    });
  };

  const handleAccept = () => {
    hidePopup(true, true);
  };

  const handleReject = () => {
    setShowCookieSettings(true);
  };

  useEffect(() => {
    const adsPermission = getCookie("ads_track_allowed");
    const analyticsPermission = getCookie("analytics_track_allowed");
    if (!adsPermission && !analyticsPermission) {
      setTimeout(() => setShowPopup(true), 2000);
    }
  }, []);

  return (
    <>
      <AnimatePresence>
        {showPopup && (
          <motion.div
            key="show-popup"
            variants={variants}
            initial="initial"
            animate="enter"
            exit="exit"
            className={styles.cookieBarWrapper}
          >
            <p>
              This website uses cookies to ensure you get the best experience.
              By using the site, you accept our use of cookies.
            </p>
            <div className={styles.ctaArea}>
              <button type="button" onClick={handleReject}>
                Manage Cookies
              </button>
              <button type="button" onClick={handleAccept}>
                Okay
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <CookieSettingsModal
        visible={showCookieSettings}
        setVisible={setShowCookieSettings}
        hidePopup={hidePopup}
      />
    </>
  );
}

export default CookieConsentPopup;
