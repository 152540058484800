exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-agriculture-js": () => import("./../../../src/pages/agriculture.js" /* webpackChunkName: "component---src-pages-agriculture-js" */),
  "component---src-pages-approval-workflows-js": () => import("./../../../src/pages/approval-workflows.js" /* webpackChunkName: "component---src-pages-approval-workflows-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-capex-js": () => import("./../../../src/pages/capex.js" /* webpackChunkName: "component---src-pages-capex-js" */),
  "component---src-pages-capex-sourcing-js": () => import("./../../../src/pages/capex-sourcing.js" /* webpackChunkName: "component---src-pages-capex-sourcing-js" */),
  "component---src-pages-capital-contact-js": () => import("./../../../src/pages/capital/contact.js" /* webpackChunkName: "component---src-pages-capital-contact-js" */),
  "component---src-pages-capital-index-js": () => import("./../../../src/pages/capital/index.js" /* webpackChunkName: "component---src-pages-capital-index-js" */),
  "component---src-pages-capital-terms-js": () => import("./../../../src/pages/capital/terms.js" /* webpackChunkName: "component---src-pages-capital-terms-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-case-study-js": () => import("./../../../src/pages/case-study.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-channel-partner-js": () => import("./../../../src/pages/channel-partner.js" /* webpackChunkName: "component---src-pages-channel-partner-js" */),
  "component---src-pages-contact-sales-js": () => import("./../../../src/pages/contact-sales.js" /* webpackChunkName: "component---src-pages-contact-sales-js" */),
  "component---src-pages-direct-materials-js": () => import("./../../../src/pages/direct-materials.js" /* webpackChunkName: "component---src-pages-direct-materials-js" */),
  "component---src-pages-eauctions-pro-js": () => import("./../../../src/pages/eauctions-pro.js" /* webpackChunkName: "component---src-pages-eauctions-pro-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-food-and-beverages-js": () => import("./../../../src/pages/food-and-beverages.js" /* webpackChunkName: "component---src-pages-food-and-beverages-js" */),
  "component---src-pages-glossary-js": () => import("./../../../src/pages/glossary.js" /* webpackChunkName: "component---src-pages-glossary-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-indirect-sourcing-js": () => import("./../../../src/pages/indirect-sourcing.js" /* webpackChunkName: "component---src-pages-indirect-sourcing-js" */),
  "component---src-pages-intake-management-js": () => import("./../../../src/pages/intake-management.js" /* webpackChunkName: "component---src-pages-intake-management-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-legal-cookie-policy-js": () => import("./../../../src/pages/legal/cookie-policy.js" /* webpackChunkName: "component---src-pages-legal-cookie-policy-js" */),
  "component---src-pages-legal-privacy-js": () => import("./../../../src/pages/legal/privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-legal-responsible-disclosure-js": () => import("./../../../src/pages/legal/responsible-disclosure.js" /* webpackChunkName: "component---src-pages-legal-responsible-disclosure-js" */),
  "component---src-pages-legal-terms-js": () => import("./../../../src/pages/legal/terms.js" /* webpackChunkName: "component---src-pages-legal-terms-js" */),
  "component---src-pages-logistics-js": () => import("./../../../src/pages/logistics.js" /* webpackChunkName: "component---src-pages-logistics-js" */),
  "component---src-pages-manufacturing-js": () => import("./../../../src/pages/manufacturing.js" /* webpackChunkName: "component---src-pages-manufacturing-js" */),
  "component---src-pages-prodiscover-contact-js": () => import("./../../../src/pages/prodiscover/contact.js" /* webpackChunkName: "component---src-pages-prodiscover-contact-js" */),
  "component---src-pages-prodiscover-supplier-js": () => import("./../../../src/pages/prodiscover/supplier.js" /* webpackChunkName: "component---src-pages-prodiscover-supplier-js" */),
  "component---src-pages-prosource-js": () => import("./../../../src/pages/prosource.js" /* webpackChunkName: "component---src-pages-prosource-js" */),
  "component---src-pages-reports-and-analytics-js": () => import("./../../../src/pages/reports-and-analytics.js" /* webpackChunkName: "component---src-pages-reports-and-analytics-js" */),
  "component---src-pages-request-callback-js": () => import("./../../../src/pages/request-callback.js" /* webpackChunkName: "component---src-pages-request-callback-js" */),
  "component---src-pages-request-demo-js": () => import("./../../../src/pages/request-demo.js" /* webpackChunkName: "component---src-pages-request-demo-js" */),
  "component---src-pages-scrap-management-js": () => import("./../../../src/pages/scrap-management.js" /* webpackChunkName: "component---src-pages-scrap-management-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-sourcing-pro-js": () => import("./../../../src/pages/sourcing-pro.js" /* webpackChunkName: "component---src-pages-sourcing-pro-js" */),
  "component---src-pages-strategic-sourcing-js": () => import("./../../../src/pages/strategic-sourcing.js" /* webpackChunkName: "component---src-pages-strategic-sourcing-js" */),
  "component---src-pages-supplier-relationship-management-software-js": () => import("./../../../src/pages/supplier-relationship-management-software.js" /* webpackChunkName: "component---src-pages-supplier-relationship-management-software-js" */),
  "component---src-pages-us-js": () => import("./../../../src/pages/us.js" /* webpackChunkName: "component---src-pages-us-js" */),
  "component---src-templates-blog-post-blog-post-jsx": () => import("./../../../src/templates/blog-post/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-jsx" */),
  "component---src-templates-case-study-case-study-jsx": () => import("./../../../src/templates/case-study/case-study.jsx" /* webpackChunkName: "component---src-templates-case-study-case-study-jsx" */),
  "component---src-templates-glossary-glossary-pages-jsx": () => import("./../../../src/templates/glossary/glossary-pages.jsx" /* webpackChunkName: "component---src-templates-glossary-glossary-pages-jsx" */)
}

