import { getCookie } from "../utils";

const BASE_URL = process.env.GATSBY_APP_BASE_URI;
const BASE_TOKEN = process.env.GATSBY_APP_BASE_TOKEN;

const promisifiedXHR = (endPoint, type, params = null) =>
  new Promise((resolve, reject) => {
    const url = BASE_URL + endPoint;

    const headers = {
      "Content-Type": "application/json",
      access_token: BASE_TOKEN,
    };

    const xhr = new XMLHttpRequest();

    xhr.onreadystatechange = function () {
      if (this.readyState === 4 && this.status >= 200 && this.status < 205) {
        const { responseText } = xhr;

        resolve(JSON.parse(responseText));
      } else if (this.readyState === 4 && this.status >= 400) {
        const { responseText } = xhr;
        reject(JSON.parse(responseText));
      }
    };

    xhr.onerror = () => reject(xhr.statusText);

    xhr.open(type, url);

    Object.keys(headers).forEach((header) => {
      xhr.setRequestHeader(header, headers[header]);
    });

    if (params) {
      xhr.send(JSON.stringify(params));
    } else {
      xhr.send();
    }
  });

const API = {
  sendLead: (data) =>
    promisifiedXHR("/company_leads", "POST", {
      ...data,
      is_test_session: getCookie("test_session"),
    }),
  getCountryCodes: () => promisifiedXHR("/countries", "GET"),
  caseStudyLeads: (data) =>
    promisifiedXHR("/api/company_leads/download_case_study", "POST", {
      ...data,
      is_test_session: getCookie("test_session"),
    }),
  fetchSupplyData: () => promisifiedXHR("/essential_supplies", "GET"),
  generateOtp: (data) => promisifiedXHR("/v1/generate_otp", "POST", data),
  verifyOtp: (data) => promisifiedXHR("/v1/verify_otp", "POST", data),
  fetchUpvote: (params) => {
    let url = "/blog/get_likes";

    if (params) {
      url += `?blog_name=${params}`;
    }

    return promisifiedXHR(url, "GET");
  },
  upvote: (params) => {
    let url = "/blog/like";

    if (params) {
      url += `?blog_name=${params}`;
    }

    return promisifiedXHR(url, "POST");
  },
  subscribeNewsletter: (data) =>
    promisifiedXHR("/blog/subscribe", "POST", data),
};
export default API;
