/* eslint-disable import/prefer-default-export */
export const articleType = {
  caseStudy: "Case Study",
  blogType: "Blog",
};

export const RESTRICTED_EMAIL_DOMAINS = [
  "gmail",
  "yahoo",
  "ymail",
  "reddif",
  "bing",
  "live",
  "icloud",
  "hotmail",
  "msn",
  "aol",
  "protonmail",
  "outlook",
];

export const REGION = {
  KEY: "region",
  VALUES: { us: "us" },
};
