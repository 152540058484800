import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql, Link } from "gatsby";
import * as styles from "./footer.module.scss";
import CookieSettingsModal from "../cookieConsentPopup/cookieSettings";
import { setTrackingPermissions } from "../../utils";

function SecondaryFooter() {
  const [showCookieSettings, setShowCookieSettings] = useState(false);

  const hidePopup = (isAdsAllowed, isAnalyticsAllowed) => {
    setShowCookieSettings(false);
    setTrackingPermissions({
      isAdsAllowed,
      isAnalyticsAllowed,
    });
  };

  const data = useStaticQuery(graphql`
    {
      cert1: file(relativePath: { eq: "footer/cert1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 90, layout: FIXED, placeholder: BLURRED)
        }
      }
      cert2: file(relativePath: { eq: "footer/cert2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 90, layout: FIXED, placeholder: BLURRED)
        }
      }
      cert3: file(relativePath: { eq: "footer/cert3.png" }) {
        childImageSharp {
          gatsbyImageData(width: 90, layout: FIXED, placeholder: BLURRED)
        }
      }
    }
  `);
  return (
    <div className={styles.secondaryFooterWrapper}>
      <div className="container">
        <div className={styles.secondaryFooterInner}>
          <div className={styles.certificates}>
            <GatsbyImage
              image={data.cert1.childImageSharp.gatsbyImageData}
              alt="cert 1"
            />
            <GatsbyImage
              image={data.cert2.childImageSharp.gatsbyImageData}
              alt="cert 2"
            />
            <GatsbyImage
              image={data.cert3.childImageSharp.gatsbyImageData}
              alt="cert 3"
            />
          </div>
          <ul className={styles.secondaryFooterText}>
            <li>&copy;2024 Procol Tech Inc.</li>
            <li>
              <Link to="/legal/terms/">Terms of Use</Link>
            </li>
            <li>
              <Link to="/legal/privacy/">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/legal/responsible-disclosure/">
                Responsible Disclosure
              </Link>
            </li>
            <li>
              <button type="button" onClick={() => setShowCookieSettings(true)}>
                Cookie Settings
              </button>
            </li>
          </ul>
        </div>
      </div>
      <CookieSettingsModal
        visible={showCookieSettings}
        setVisible={setShowCookieSettings}
        hidePopup={hidePopup}
      />
    </div>
  );
}

export default SecondaryFooter;
