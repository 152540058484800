import React, { useEffect, useRef } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import Glide from "@glidejs/glide";
import classNames from "classnames";
import * as styles from "./reviewSlider.module.scss";
import "@glidejs/glide/dist/css/glide.core.min.css";
import ArrowRight from "../../../static/images/landing/arrow-right-carousel.svg";
import ArrowLeft from "../../../static/images/landing/arrow-left-carousel.svg";

const glideConfig = {
  autoplay: 4000,
  type: "carousel",
  focusAt: "center",
  perView: 1.2,
  gap: 5,
  peek: {
    before: 16,
    after: 16,
  },
  breakpoints: {
    1200: {
      perView: 1.5,
    },
    650: {
      perView: 1,
    },
  },
};

const modalGlideConfig = {
  autoplay: 3000,
  type: "carousel",
  focusAt: "center",
  perView: 1,
  gap: 0,
  peek: 1,
};

function ReviewSlider({ reviewData, heading, isInModal }) {
  const sliderRef = useRef();
  const glideRef = useRef();

  const data = useStaticQuery(graphql`
    {
      anand: file(relativePath: { eq: "clients/anand.jpg" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 80, layout: FIXED, placeholder: BLURRED)
        }
      }
      roshan: file(relativePath: { eq: "clients/roshan.jpg" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 80, layout: FIXED, placeholder: BLURRED)
        }
      }
      more: file(relativePath: { eq: "clients/more-retail-logo.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 90, layout: FIXED, placeholder: BLURRED)
        }
      }
      bigbasket: file(relativePath: { eq: "landing/logos/bigbasket.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 110, layout: FIXED, placeholder: BLURRED)
        }
      }
      akshaypatra: file(relativePath: { eq: "clients/akshaypatra.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 110, layout: FIXED, placeholder: BLURRED)
        }
      }
      usperson1: file(relativePath: { eq: "clients/usperson1.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 80, layout: FIXED, placeholder: BLURRED)
        }
      }
      usperson2: file(relativePath: { eq: "clients/usperson2.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 80, layout: FIXED, placeholder: BLURRED)
        }
      }
      usperson3: file(relativePath: { eq: "clients/usperson3.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 80, layout: FIXED, placeholder: BLURRED)
        }
      }
      usperson4: file(relativePath: { eq: "clients/usperson4.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 80, layout: FIXED, placeholder: BLURRED)
        }
      }
    }
  `);

  useEffect(() => {
    const activeGlideConfig = isInModal ? modalGlideConfig : glideConfig;
    glideRef.current = new Glide(sliderRef.current, activeGlideConfig);
    glideRef.current.mount();
  }, [sliderRef]);

  const reviews = reviewData.map(
    ({ image, review, name, title, company, round }) => (
      <div className={classNames("glide__slide", styles.reviewBody)} key={name}>
        <blockquote>{review}</blockquote>
        <cite>
          <div>
            <p>{name}</p>
            <span>
              {title}
              <br />
              {company}
            </span>
          </div>
          <div className={styles.reviewLogo}>
            <GatsbyImage
              image={data[image].childImageSharp.gatsbyImageData}
              alt={data[image].name}
              style={{ borderRadius: round && "100px" }}
            />
          </div>
        </cite>
      </div>
    ),
  );

  return (
    <section
      className={classNames(styles.reviewSectionWrapper, {
        [styles.hasModal]: isInModal,
      })}
    >
      <div className="container">
        {heading && <h2>{heading}</h2>}
        <div ref={sliderRef} className="glide">
          <div data-glide-el="track" className={styles.glideTrack}>
            <ul className="glide__slides">{reviews}</ul>
          </div>
        </div>

        {!isInModal && (
          <div className={styles.glideControls}>
            <button type="button" onClick={() => glideRef.current.go("<")}>
              <img alt="arrow" src={ArrowLeft} />
            </button>
            <button type="button" onClick={() => glideRef.current.go(">")}>
              <img alt="arrow" src={ArrowRight} />
            </button>
          </div>
        )}
      </div>
    </section>
  );
}

export default ReviewSlider;
