import React from "react";
import LeadForm, { LEAD_TYPE } from "./leadForm";
import * as styles from "./contactSales.module.scss";
import { getRegion } from "../../utils";
import { REGION } from "../../../content/industriesPages/constants";

function ContactSalesForm({
  leadType = LEAD_TYPE.salesCallback,
  isModalForm,
  formHeading = "Schedule a Call",
}) {
  const isUSRegion = getRegion() === REGION.VALUES.us;
  return (
    <div className={styles.contactSalesInner}>
      <div className={styles.contactSalesForm}>
        <div className={styles.contactSalesDetails}>
          <h1>{formHeading}</h1>
          <p className={styles.content}>
            We&apos;d love to hear from you. Please fill out this form to
            schedule a demo with us.
            <p>
              You can also give a call on
              {isUSRegion ? (
                <a href="tel:+12093054922"> +1 (209) 305-4922</a>
              ) : (
                <a href="tel:+917666682222"> +91 76666 82222</a>
              )}
            </p>
          </p>
        </div>
        <LeadForm
          leadType={leadType}
          hideReqField={isModalForm}
          isUSRegion={isUSRegion}
        />
      </div>
    </div>
  );
}

export default ContactSalesForm;
