import { useContext, RefObject, useEffect } from "react";
import { GlobalContext } from "./context/global-context";
import { LEAD_TYPE } from "./components/contactSales/leadForm";

export const useLeadFormModal = () => {
  const context = useContext(GlobalContext);

  const { setLeadFormVisible, setLeadFormType } = context || {};

  const setLeadModal = (e, visible = true, type = LEAD_TYPE.demoCallback) => {
    e?.preventDefault();
    setLeadFormVisible(visible);
    setLeadFormType(type);
  };

  return setLeadModal;
};
