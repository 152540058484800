import React, { useContext, useEffect, useState } from "react";
import * as styles from "./countryCodeSelector.module.scss";
import classNames from "classnames";
import { GlobalContext } from "../../context/global-context";
import API from "../../services/api";
import { isArray } from "../../utils";

function CountryCodeSelector({
  country,
  setSelectedCountry,
  isRenderingFromBlog,
}) {
  const context = useContext(GlobalContext);
  const { countryCodes, setCountryCodes, setCountry } = context || {};

  useEffect(() => {
    if (!isArray(countryCodes)) {
      API.getCountryCodes()
        .then((res) => {
          setCountryCodes(res.countries);

          const { country_code, name, phone_number_length, phone_prefix } =
            res.user_country;
          const countryData = {
            countryCode: country_code,
            name,
            phoneNumberLength: phone_number_length,
            phonePrefix: phone_prefix,
          };
          setCountry(countryData);
          setSelectedCountry(countryData);
        })
        .catch(() => {});
    }
  }, [countryCodes]);
  return (
    <div className={styles.selectContainer}>
      <div
        className={classNames(styles.countryCodeDiv, {
          [styles.countryCodeBlog]: isRenderingFromBlog,
        })}
      >
        {country?.phonePrefix || "+91"}
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M8 10.6667L4 6.66675H12L8 10.6667Z" fill="currentColor" />
        </svg>
      </div>
      <select
        id="country"
        size="large"
        value={country?.phonePrefix || "+91"}
        onChange={(e) => {
          const selectedCountry = countryCodes.find(
            (c) => c.phone_prefix === e.target.value,
          );
          const { country_code, name, phone_number_length, phone_prefix } =
            selectedCountry;
          setSelectedCountry({
            countryCode: country_code,
            name,
            phoneNumberLength: phone_number_length,
            phonePrefix: phone_prefix,
          });
        }}
      >
        {Array.isArray(countryCodes) &&
          countryCodes.map((country) => (
            <option key={country.name} value={country.phone_prefix}>
              {`${country.name}  (${country.phone_prefix})`}{" "}
            </option>
          ))}
      </select>
    </div>
  );
}
export default CountryCodeSelector;
