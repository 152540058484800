import React, { useContext, useEffect, useRef } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import classNames from "classnames";
import { motion } from "framer-motion";
import * as styles from "./contactSales.module.scss";
import ContactSalesForm from "./contactSalesForm";
import { GlobalContext } from "../../context/global-context";
import ReviewSlider from "../reviewSlider/reviewSlider";
import Logo from "../logo";
import closeIcon from "../../../static/images/close.svg";
import { useLeadFormModal } from "../../hooks";
import { LEAD_TYPE } from "./leadForm";
import { getRegion } from "../../utils";
import { REGION } from "../../../content/industriesPages/constants";

const duration = 0.2;

const variants = {
  initial: {
    opacity: 0,
    y: 15,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration,
      delay: 0,
      when: "beforeChildren",
    },
  },
};

const trimmedReviewData = [
  {
    review: (
      <>
        &ldquo;ROI was justified within a few months of usage. They&apos;ve
        saved us millions by automating our auction processes.&rdquo;
      </>
    ),
    name: "Anand Jaju",
    title: "  Procurement Head ",
    company: "Future Group",
    image: "anand",
    round: true,
  },
  {
    review: (
      <>
        &ldquo;Procol has helped us put a system in place with commercial
        benefit for the procurement of most complex and critical product line of
        our business.&rdquo;
      </>
    ),
    name: "Roshan Karn",
    title: "Asst. Purchase Manager",
    company: "Allana Group",
    image: "roshan",
    round: true,
  },
  {
    review: (
      <>
        &ldquo;Procol helped us bring cost reduction on 2% on our purchase.
        Along with that our TAT for closing order has come down from 24 hours to
        45 min.&rdquo;
      </>
    ),
    name: "Yogesh Dehankar",
    title: "Category Head, Pulses",
    company: "BigBasket",
    image: "bigbasket",
    round: false,
  },
  {
    review: (
      <>
        &ldquo;I think Procol is the best product on the market. It&apos;s a
        great platform for anyone who would like to create transparency and
        audit trail in their procurements.&rdquo;
      </>
    ),
    name: "Nisheeth Jain",
    title: "Finance Team",
    company: "More Retail Limited",
    image: "more",
    round: false,
  },
];

const trimmedUSReviewData = [
  {
    review: (
      <>
        &ldquo;ROI was justified within a few months of usage. They&apos;ve
        saved us millions by automating our auction processes.&rdquo;
      </>
    ),
    name: "William",
    title: "Procurement Head",
    image: "usperson1",
    round: true,
  },
  {
    review: (
      <>
        &ldquo;Procol has helped us put a system in place with commercial
        benefit for the procurement of most complex and critical product line of
        our business.&rdquo;
      </>
    ),
    name: "Steve",
    title: "Asst. Purchase Manager",
    image: "usperson2",
    round: true,
  },
  {
    review: (
      <>
        &ldquo;Procol helped us bring cost reduction on 2% on our purchase.
        Along with that our TAT for closing order has come down from 24 hours to
        45 min.&rdquo;
      </>
    ),
    name: "David",
    title: "Category Head, Pulses",
    image: "usperson3",
    round: true,
  },
  {
    review: (
      <>
        &ldquo;I think Procol is the best product on the market. It&apos;s a
        great platform for anyone who would like to create transparency and
        audit trail in their procurements.&rdquo;
      </>
    ),
    name: "Martin",
    title: "Finance Team",
    image: "usperson4",
    round: true,
  },
];

const ContactSalesModal = () => {
  const testimonialData =
    getRegion() === REGION.VALUES.us ? trimmedUSReviewData : trimmedReviewData;
  const context = useContext(GlobalContext);
  const setLeadModal = useLeadFormModal();
  const leadFormVisible = context && context.leadFormVisible;
  const leadFormType = context && context.leadFormType;
  const setLeadFormVisible = context && context.setLeadFormVisible;

  const clientsLogo = useStaticQuery(graphql`
    {
      noon: file(relativePath: { eq: "lead-popup/noon.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 100
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
      qdb: file(relativePath: { eq: "lead-popup/qdb.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 100
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
      amns: file(relativePath: { eq: "lead-popup/amns.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 100
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
      allana: file(relativePath: { eq: "lead-popup/allana.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 100
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
      pfl: file(relativePath: { eq: "lead-popup/pfl.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 100
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
      bb: file(relativePath: { eq: "lead-popup/bb.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 100
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
      dalmia: file(relativePath: { eq: "lead-popup/dalmia.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 100
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
      havells: file(relativePath: { eq: "lead-popup/havells.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 100
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
      signature: file(relativePath: { eq: "lead-popup/signature.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 100
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
      nlmk: file(relativePath: { eq: "lead-popup/nlmk.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 100
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
  `);

  useEffect(() => {
    // stop body scroll when modal is visible
    if (leadFormVisible) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    return () => document.body.classList.remove("modal-open");
  }, [leadFormVisible]);

  if (!leadFormVisible) {
    return null;
  }

  return (
    <motion.div
      key="form"
      variants={variants}
      initial="initial"
      animate="enter"
      className={classNames(styles.contactSalesModalOverlay, {
        [styles.visible]: leadFormVisible,
      })}
    >
      <div className={styles.contactSalesModal}>
        <button
          type="button"
          aria-label="close modal"
          className={styles.modalCloseBtn}
          onClick={(e) => setLeadModal(e, false)}
        >
          <img src={closeIcon} alt="close icon" />
        </button>
        <div className={styles.formWrapper}>
          <span className={styles.logo}>
            <Logo />
          </span>
          <ContactSalesForm
            isModalForm
            leadType={LEAD_TYPE.demoCallback}
            formHeading={
              leadFormType === LEAD_TYPE.salesCallback
                ? "Schedule a Call"
                : "Get Free Demo"
            }
          />
        </div>
        <div className={styles.testimonialsWrapper}>
          <span className={styles.logo}>
            <Logo />
          </span>
          <div className={styles.testimonialsData}>
            <div className={styles.clientsList}>
              <h3>Trusted by leading procurement teams across the globe</h3>
              <div className={styles.modalCompaniesLogos}>
                <GatsbyImage
                  image={clientsLogo.noon.childImageSharp.gatsbyImageData}
                  alt="noon logo"
                />
                <GatsbyImage
                  image={clientsLogo.qdb.childImageSharp.gatsbyImageData}
                  alt="qdb logo"
                />
                <GatsbyImage
                  image={clientsLogo.amns.childImageSharp.gatsbyImageData}
                  alt="amns logo"
                />
                <GatsbyImage
                  image={clientsLogo.allana.childImageSharp.gatsbyImageData}
                  alt="allana logo"
                  className={styles.hideOnMobile}
                />
                <GatsbyImage
                  image={clientsLogo.pfl.childImageSharp.gatsbyImageData}
                  alt="pfl logo"
                  className={styles.hideOnMobile}
                />
                <GatsbyImage
                  image={clientsLogo.bb.childImageSharp.gatsbyImageData}
                  alt="big basket logo"
                  className={styles.hideOnMobile}
                />
                <GatsbyImage
                  image={clientsLogo.dalmia.childImageSharp.gatsbyImageData}
                  alt="dalmia logo"
                />
                <GatsbyImage
                  image={clientsLogo.havells.childImageSharp.gatsbyImageData}
                  alt="havells logo"
                />
                <GatsbyImage
                  image={clientsLogo.signature.childImageSharp.gatsbyImageData}
                  alt="signature logo"
                />
                <GatsbyImage
                  image={clientsLogo.nlmk.childImageSharp.gatsbyImageData}
                  alt="nlmk logo"
                  className={styles.hideOnMobile}
                />
              </div>
            </div>
            <ReviewSlider isInModal reviewData={testimonialData} />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ContactSalesModal;
