/* eslint-disable  */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import classNames from "classnames";

import Logo from "../../logo";
import "./styles.scss";
import { GlobalContext } from "../../../context/global-context";
import { trackEvent } from "../../../utils";
import { useLeadFormModal } from "../../../hooks";
import { LEAD_TYPE } from "../../contactSales/leadForm";

const subMenuAnimate = {
  enter: {
    opacity: 1,
    rotateX: 0,
    y: 0,
    transition: {
      duration: 0.3,
    },
    display: "block",
  },
  exit: {
    opacity: 0,
    rotateX: -10,
    y: -5,
    transition: {
      duration: 0.3,
    },
    transitionEnd: {
      display: "none",
    },
  },
};

const MENU_TABS = {
  solution: 0,
  industries: 1,
  resources: 2,
  more: 3,
  categories: 4,
};

function DesktopHeader({
  isWhite,
  isHome,
  isProDiscoverPage,
  isProCapitalPage,
  isProSourcePage,
  location,
}) {
  const [currentMenu, setCurrentMenu] = useState(MENU_TABS.solution);
  const [homeRoute, setHomeRoute] = useState("/");
  const context = useContext(GlobalContext);
  const setLeadModalVisible = useLeadFormModal();
  const navBG = context && context.navBG;
  const allowBg = () => {
    if (isHome) return navBG;
    return !isWhite || navBG;
  };
  const handleNavigate = (url) => {
    window.location.href = location.origin + url;
  };
  useEffect(() => {
    setCurrentMenu(false);
  }, [location.href]);

  useEffect(() => {
    setHomeRoute(location.origin);
  }, [location]);

  return (
    <header
      className={classNames("header__desktop", {
        "nav-bg": allowBg(),
      })}
    >
      <div className="container">
        <nav
          className={classNames("navbar", {
            isWhite: !navBG && isWhite,
          })}
        >
          <div className="logo_menu">
            <button
              type="button"
              className="navbar__logo"
              aria-label="link for home page"
              onClick={() => {
                window.location.href = homeRoute;
              }}
            >
              <Logo isWhite={!navBG && isWhite} />
            </button>
            <ul className="menu">
              <li className="menu-option" onMouseLeave={() => setCurrentMenu()}>
                <span onMouseOver={() => setCurrentMenu(MENU_TABS.solution)}>
                  Solutions{" "}
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 10.6667L4 6.66675H12L8 10.6667Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <motion.ul
                  className="sub-menu"
                  initial="exit"
                  animate={
                    currentMenu === MENU_TABS.solution ? "enter" : "exit"
                  }
                  variants={subMenuAnimate}
                >
                  {/* <li>
                    <Link to="/capital/">ProCapital</Link>
                  </li>
                  <li>
                    <Link to="/prodiscover/buyer/">ProDiscover</Link>
                  </li>
                  <li>
                    <Link to="/prosource/">ProSource</Link>
                  </li> */}

                  <li>
                    <a
                      role="button"
                      onClick={() => handleNavigate(`/sourcing-pro/`)}
                    >
                      Sourcing Pro
                    </a>
                  </li>
                  <li>
                    <Link to="/strategic-sourcing/">Strategic Sourcing</Link>
                  </li>
                  <li>
                    <Link to="/indirect-sourcing/">Indirect Sourcing</Link>
                  </li>
                  <li>
                    <Link to="/capex-sourcing/">Capex Sourcing</Link>
                  </li>
                  <li>
                    <a
                      onClick={() => handleNavigate(`/eauctions-pro/`)}
                      rel="noopener noreferrer"
                    >
                      eAuctions Pro
                    </a>
                  </li>
                  <li>
                    <Link to="/supplier-relationship-management-software/">
                      Supplier Management
                    </Link>
                  </li>
                  <li>
                    <Link to="/intake-management/">Intake Management</Link>
                  </li>
                  <li>
                    <Link to="/approval-workflows/">Approval Workflows</Link>
                  </li>
                  <li>
                    <Link to="/reports-and-analytics/">
                      Reports and Analytics
                    </Link>
                  </li>
                  <li>
                    <Link to="/integrations/">Integrations</Link>
                  </li>
                </motion.ul>
              </li>

              {/* <li
                className='menu-option onMouseLeave={() => setCurrentMenu()}'
              >
                <span onMouseEnter={() => setCurrentMenu(MENU_TABS.industries)}
                >
                  Industries{" "}
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 10.6667L4 6.66675H12L8 10.6667Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <motion.ul
                  className="sub-menu"
                  initial="exit"
                  animate={
                    currentMenu === MENU_TABS.industries ? "enter" : "exit"
                  }
                  variants={subMenuAnimate}
                >
                  <li>
                    <Link activeClassName="active" to="/manufacturing/">
                      Manufacturing
                    </Link>
                  </li>
                  <li>
                    <Link activeClassName="active" to="/food-and-beverages/">
                      Food and Beverages
                    </Link>
                  </li>
                </motion.ul>
              </li> */}
              {/* <li className="menu-option" onMouseLeave={() => setCurrentMenu()}>
                <span onMouseEnter={() => setCurrentMenu(MENU_TABS.categories)}>
                  Categories
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 10.6667L4 6.66675H12L8 10.6667Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <motion.ul
                  className="sub-menu"
                  initial="exit"
                  animate={
                    currentMenu === MENU_TABS.categories ? "enter" : "exit"
                  }
                  variants={subMenuAnimate}
                >
                  <li>
                    <Link activeClassName="active" to="/agriculture/">
                      Agriculture
                    </Link>
                  </li>
                  <li>
                    <Link activeClassName="active" to="/capex/">
                      Capex
                    </Link>
                  </li>
                  <li>
                    <Link activeClassName="active" to="/direct-materials/">
                      Direct Materials
                    </Link>
                  </li>
                  <li>
                    <Link activeClassName="active" to="/logistics/">
                      Logistics
                    </Link>
                  </li>

                  <li>
                    <Link activeClassName="active" to="/services/">
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link activeClassName="active" to="/scrap-management/">
                      Scrap Management
                    </Link>
                  </li>
                </motion.ul>
              </li> */}
              <li className="menu-option" onMouseLeave={() => setCurrentMenu()}>
                <span onMouseOver={() => setCurrentMenu(MENU_TABS.resources)}>
                  Resources{" "}
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 10.6667L4 6.66675H12L8 10.6667Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <motion.ul
                  className="sub-menu"
                  initial="exit"
                  animate={
                    currentMenu === MENU_TABS.resources ? "enter" : "exit"
                  }
                  variants={subMenuAnimate}
                >
                  <li>
                    {/* <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.5">
                        <path
                          d="M15.8327 18.3334H4.16602C3.50297 18.3334 2.86709 18.07 2.39825 17.6012C1.92941 17.1323 1.66602 16.4965 1.66602 15.8334V2.50008C1.66602 2.27907 1.75381 2.06711 1.91009 1.91083C2.06637 1.75455 2.27834 1.66675 2.49935 1.66675H14.166C14.387 1.66675 14.599 1.75455 14.7553 1.91083C14.9116 2.06711 14.9993 2.27907 14.9993 2.50008V12.5001H18.3327V15.8334C18.3327 16.4965 18.0693 17.1323 17.6005 17.6012C17.1316 18.07 16.4957 18.3334 15.8327 18.3334ZM14.9993 14.1667V15.8334C14.9993 16.0544 15.0871 16.2664 15.2434 16.4227C15.3997 16.579 15.6117 16.6667 15.8327 16.6667C16.0537 16.6667 16.2657 16.579 16.4219 16.4227C16.5782 16.2664 16.666 16.0544 16.666 15.8334V14.1667H14.9993ZM13.3327 16.6667V3.33341H3.33268V15.8334C3.33268 16.0544 3.42048 16.2664 3.57676 16.4227C3.73304 16.579 3.945 16.6667 4.16602 16.6667H13.3327ZM4.99935 5.83342H11.666V7.50008H4.99935V5.83342ZM4.99935 9.16675H11.666V10.8334H4.99935V9.16675ZM4.99935 12.5001H9.16602V14.1667H4.99935V12.5001Z"
                          fill="black"
                        />
                      </g>
                    </svg> */}
                    <Link activeClassName="active" to="/blog/">
                      Blog
                    </Link>
                  </li>
                  <li>
                    {/* <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.50">
                        <path
                          d="M16.6667 18.3334H3.33333C3.11232 18.3334 2.90036 18.2456 2.74408 18.0893C2.5878 17.9331 2.5 17.7211 2.5 17.5001V2.50008C2.5 2.27907 2.5878 2.06711 2.74408 1.91083C2.90036 1.75455 3.11232 1.66675 3.33333 1.66675H16.6667C16.8877 1.66675 17.0996 1.75455 17.2559 1.91083C17.4122 2.06711 17.5 2.27907 17.5 2.50008V17.5001C17.5 17.7211 17.4122 17.9331 17.2559 18.0893C17.0996 18.2456 16.8877 18.3334 16.6667 18.3334ZM15.8333 16.6667V3.33341H4.16667V16.6667H15.8333ZM5.83333 5.00008H9.16667V8.33342H5.83333V5.00008ZM5.83333 10.0001H14.1667V11.6667H5.83333V10.0001ZM5.83333 13.3334H14.1667V15.0001H5.83333V13.3334ZM10.8333 5.83342H14.1667V7.50008H10.8333V5.83342Z"
                          fill="black"
                        />
                      </g>
                    </svg> */}
                    <Link activeClassName="active" to="/case-study/">
                      Case Study
                    </Link>
                  </li>
                  <li>
                    {/* <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.2109 0H3.78906C2.49672 0 1.44531 1.05141 1.44531 2.34375V17.6562C1.44531 18.9486 2.49672 20 3.78906 20H16.2109C17.5033 20 18.5547 18.9486 18.5547 17.6562V2.34375C18.5547 1.05141 17.5033 0 16.2109 0ZM16.9922 17.6562C16.9922 18.087 16.6417 18.4375 16.2109 18.4375H3.78906C3.35828 18.4375 3.00781 18.087 3.00781 17.6562V6.71875H16.9922V17.6562ZM16.9922 5.15625H3.00781V2.34375C3.00781 1.91297 3.35828 1.5625 3.78906 1.5625H16.2109C16.6417 1.5625 16.9922 1.91297 16.9922 2.34375V5.15625Z"
                        fill="black"
                        fillOpacity="0.5"
                      />
                      <path
                        d="M15.2344 11.8H13.6719V16.3282H15.2344V11.8Z"
                        fill="black"
                        fillOpacity="0.5"
                      />
                      <path
                        d="M6.36719 8.82812H4.80469V10.3906H6.36719V8.82812Z"
                        fill="black"
                        fillOpacity="0.5"
                      />
                      <path
                        d="M9.33594 8.82812H7.77344V10.3906H9.33594V8.82812Z"
                        fill="black"
                        fillOpacity="0.5"
                      />
                      <path
                        d="M12.2656 8.82812H10.7031V10.3906H12.2656V8.82812Z"
                        fill="black"
                        fillOpacity="0.5"
                      />
                      <path
                        d="M6.36719 11.7969H4.80469V13.3594H6.36719V11.7969Z"
                        fill="black"
                        fillOpacity="0.5"
                      />
                      <path
                        d="M9.33594 11.7969H7.77344V13.3594H9.33594V11.7969Z"
                        fill="black"
                        fillOpacity="0.5"
                      />
                      <path
                        d="M12.2656 11.7969H10.7031V13.3594H12.2656V11.7969Z"
                        fill="black"
                        fillOpacity="0.5"
                      />
                      <path
                        d="M15.2344 8.82812H13.6719V10.3906H15.2344V8.82812Z"
                        fill="black"
                        fillOpacity="0.5"
                      />
                      <path
                        d="M6.36719 14.7656H4.80469V16.3281H6.36719V14.7656Z"
                        fill="black"
                        fillOpacity="0.5"
                      />
                      <path
                        d="M9.33594 14.7656H7.77344V16.3281H9.33594V14.7656Z"
                        fill="black"
                        fillOpacity="0.5"
                      />
                      <path
                        d="M12.2656 14.7656H10.7031V16.3281H12.2656V14.7656Z"
                        fill="black"
                        fillOpacity="0.5"
                      />
                    </svg> */}
                    <Link to="/#roi">Savings Calculator</Link>
                  </li>
                  <li>
                    {/* <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.50">
                        <path
                          d="M16.6667 18.3334H3.33333C3.11232 18.3334 2.90036 18.2456 2.74408 18.0893C2.5878 17.9331 2.5 17.7211 2.5 17.5001V2.50008C2.5 2.27907 2.5878 2.06711 2.74408 1.91083C2.90036 1.75455 3.11232 1.66675 3.33333 1.66675H16.6667C16.8877 1.66675 17.0996 1.75455 17.2559 1.91083C17.4122 2.06711 17.5 2.27907 17.5 2.50008V17.5001C17.5 17.7211 17.4122 17.9331 17.2559 18.0893C17.0996 18.2456 16.8877 18.3334 16.6667 18.3334ZM15.8333 16.6667V3.33341H4.16667V16.6667H15.8333ZM5.83333 5.00008H9.16667V8.33342H5.83333V5.00008ZM5.83333 10.0001H14.1667V11.6667H5.83333V10.0001ZM5.83333 13.3334H14.1667V15.0001H5.83333V13.3334ZM10.8333 5.83342H14.1667V7.50008H10.8333V5.83342Z"
                          fill="black"
                        />
                      </g>
                    </svg> */}

                    <Link activeClassName="active" to="/channel-partner/">
                      Become a Partner
                    </Link>
                  </li>
                  <li>
                    {/* <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.5">
                        <path
                          d="M15.8327 18.3334H4.16602C3.50297 18.3334 2.86709 18.07 2.39825 17.6012C1.92941 17.1323 1.66602 16.4965 1.66602 15.8334V2.50008C1.66602 2.27907 1.75381 2.06711 1.91009 1.91083C2.06637 1.75455 2.27834 1.66675 2.49935 1.66675H14.166C14.387 1.66675 14.599 1.75455 14.7553 1.91083C14.9116 2.06711 14.9993 2.27907 14.9993 2.50008V12.5001H18.3327V15.8334C18.3327 16.4965 18.0693 17.1323 17.6005 17.6012C17.1316 18.07 16.4957 18.3334 15.8327 18.3334ZM14.9993 14.1667V15.8334C14.9993 16.0544 15.0871 16.2664 15.2434 16.4227C15.3997 16.579 15.6117 16.6667 15.8327 16.6667C16.0537 16.6667 16.2657 16.579 16.4219 16.4227C16.5782 16.2664 16.666 16.0544 16.666 15.8334V14.1667H14.9993ZM13.3327 16.6667V3.33341H3.33268V15.8334C3.33268 16.0544 3.42048 16.2664 3.57676 16.4227C3.73304 16.579 3.945 16.6667 4.16602 16.6667H13.3327ZM4.99935 5.83342H11.666V7.50008H4.99935V5.83342ZM4.99935 9.16675H11.666V10.8334H4.99935V9.16675ZM4.99935 12.5001H9.16602V14.1667H4.99935V12.5001Z"
                          fill="black"
                        />
                      </g>
                    </svg> */}
                    <Link activeClassName="active" to="/glossary/">
                      Glossary
                    </Link>
                  </li>
                </motion.ul>
              </li>
              <li className="menu-option">
                <span>
                  <a href="https://www.procol.io/pricing">Pricing</a>
                </span>
              </li>
              {/* <li
                className='menu-option onMouseLeave={() => setCurrentMenu()}'
              >
                <span onMouseEnter={() => setCurrentMenu(MENU_TABS.more)}
                >
                  More{" "}
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 10.6667L4 6.66675H12L8 10.6667Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <motion.ul
                  className="sub-menu"
                  initial="exit"
                  animate={currentMenu === MENU_TABS.more ? "enter" : "exit"}
                  variants={subMenuAnimate}
                >
                  <li>
                    <Link activeClassName="active" to="/channel-partner/">
                      Become a Partner
                    </Link>
                  </li>
                </motion.ul>
              </li> */}
            </ul>
          </div>

          <div className="navbar__cta">
            <span>
              {isProDiscoverPage && (
                <Link to="/prodiscover/contact/" className="btn--blue">
                  Get in Touch
                </Link>
              )}
              {isProSourcePage && (
                <Link
                  to="/request-demo/"
                  className="btn--blue"
                  onClick={setLeadModalVisible}
                >
                  Free Demo
                </Link>
              )}
              {isProCapitalPage && (
                <Link to="/capital/contact/" className="btn--blue">
                  Apply Now
                </Link>
              )}
              {!isProDiscoverPage && !isProCapitalPage && !isProSourcePage && (
                <a
                  onClick={() => handleNavigate("/contact-sales/")}
                  className="btn contact-sales"
                >
                  Contact Sales
                </a>
              )}
              {!isProDiscoverPage && !isProCapitalPage && !isProSourcePage && (
                <Link
                  className="btn"
                  to="/request-demo/"
                  onClick={(e) => {
                    setLeadModalVisible(e);
                    trackEvent(`desktop nav on ${window.location.pathname}`);
                  }}
                >
                  Free Demo
                </Link>
              )}
            </span>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default DesktopHeader;
